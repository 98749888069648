import React, { useEffect } from 'react';

import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { TransparentPlaceholder } from '@components/Based/Placeholder';
import { useAppSelector } from '@store/hooks';

const NjoiRoute: React.FC<any> = ({
  path,
  fullPath,
  childComponent,
  component: Component
}) => {
  const language = useAppSelector((state) => state.language.language);
  const history = useHistory();

  useEffect(() => {
    if (path === '') {
      history.replace(`/${language.code}-home`);
    }
  }, [path, history, language.code]);

  return (
    <Component fallback={<TransparentPlaceholder />}>
      {childComponent && childComponent.length > 0 && (
        <Switch>
          {childComponent.map(
            ({ path, exact, component: SubComponent }: any) => (
              <Route
                key={path}
                path={`${fullPath}/${path}`}
                render={() => (
                  <SubComponent fallback={<TransparentPlaceholder />} />
                )}
                exact={exact}
              />
            )
          )}
          <Redirect to={fullPath} />
        </Switch>
      )}
    </Component>
  );
};

export default NjoiRoute;
