const bm: LanguageResource = {
  common: {
    home: 'Utama',
    privacyNotice: 'Notis Privasi',
    getNjoi: 'Dapatkan NJOI',
    termsAndConditions: 'Terma & Syarat',
    registerInterest: 'Daftar Minat',
    submit: 'Hantar',
    fullName: 'Nama Penuh',
    installation: 'Pemasangan',
    installationAddress: 'Alamat Pemasangan',
    email: 'E-mel',
    inputEmptyError: 'Ruang ini perlu diisi',
    select: 'Pilih',
    technicalIssueMessage:
      'Maaf, kami sedang mengalami masalah teknikal. Sila cuba sebentar lagi.',
    call: 'Panggilan',
    closePage:
      '<0>Nota:</0> Tekan X untuk menutup halaman dan kembali ke My NJOI app.',
    broadband: 'Broadband',
    expandingCoverageMessage:
      'Kami sedang mengembangkan liputan broadband kami.',
    changeAddress: 'Tukar alamat',
    mobileNumber: 'Nombor telefon bimbit',
    altNumber: 'Nombor alternatif',
    proceed: 'Teruskan',
    mobilePrefixEmptyError: 'Sila pilih awalan mudah alih',
    landed: 'Bertanah',
    multiStorey: 'Bertingkat',
    somethingWentWrong: 'Oops, berlaku masalah',
    okay: 'OK',
    close: 'TUTUP',
    termsAndConditions2: 'Terma dan Syarat',
    monthlyFee: 'Caj bulanan',
    oneTimeFee: 'Caj sekali sahaja',
    confirmOrder: 'Sahkan Tempahan',
    firstMonthFree:
      'PERCUMA untuk bulan pertama. Bil anda untuk bulan pertama akan diketepikan.',
    free: 'PERCUMA',
    signUp: 'Daftar',
    existingCustomer: 'Pelanggan yang sedia ada?',
    purchaseViaApp: 'Beli melalui My NJOI app',
    includedChannels: 'Termasuk Saluran',
    njoiChannelAndPackage: 'NJOI Saluran & Pek',
    minute_zero: '{{count}} minit',
    minute_one: '{{count}} minit',
    minute_other: '{{count}} minit',
    mins: '{{count}} minit',
    day_zero: 'untuk {{count}} hari',
    day_one: 'untuk {{count}} hari',
    day_other: 'untuk {{count}} hari',
    dayUnit_zero: '{{count}} hari',
    dayUnit_one: '{{count}} hari',
    dayUnit_other: '{{count}} hari',
    packs: 'Pek',
    starterPack: 'Starter Pek',
    addOnPack: 'Pek Add-on',
    channels: 'Saluran',
    movies: 'Filem',
    onNow: 'Sekarang',
    today: 'Hari ini',
    mon: 'Isnin',
    tue: 'Selasa',
    wed: 'Rabu',
    thu: 'Khamis',
    fri: 'Jumaat',
    sat: 'Sabtu',
    sun: 'Ahad',
    noInformationAvailable: 'Tiada maklumat disediakan',
    from: 'Dari',
    whatsAppButtonText: 'mesej kami',
    whatsAppButtonDialogTitle: 'Mesej Kami',
    whatsAppButtonDialogMessage: 'Hai! Perlukan bantuan?',
    distributor:
      'Boleh didapati di <0>pengedar rasmi</0> (promo eksklusif hanya untuk pembelian dalam talian), <1>Go Shop</1>, <2>Shopee</2> dan <3>Lazada</3>.'
  },
  language: {
    eng: 'Bahasa Inggeris',
    may: 'Bahasa Malaysia',
    chi: 'Bahasa Cina',
    ind: 'Bahasa Indonesia',
    tam: 'Bahasa Tamil',
    kor: 'Bahasa Korea',
    jpn: 'Bahasa Jepun',
    cmn: 'Bahasa Mandarin'
  },
  seo: {
    home: {
      title: 'Home',
      description:
        'Dapatkan NJOI HD Box anda sekarang untuk lebih banyak pek prabayar yang fleksibel, tiada kontrak diperlukan dan tiada bil bulanan. Hanya satu kali peralatan dan yuran pemasangan dan anda sudah bersedia seumur hidup!'
    },
    privacyNotice: {
      description:
        'Akta Perlindungan Data Peribadi 2010 telah diperkenalkan oleh pihak Kerajaan untuk mengawal selia pemprosesan data dalam transaksi komersial. Akta ini, yang merangkumi semua syarikat-syarikat dan firma-firma yang ditubuhkan di Malaysia, mewajibkan kami memaklumkan anda berkenaan hak-hak anda berkaitan data peribadi anda yang sedang diproses atau yang akan dikumpulkan dan yang akan diproses oleh pihak kami serta maksud-maksud bagi pemprosesan data tersebut. Akta ini juga mewajibkan kami mendapatkan persetujuan anda terhadap pemprosesan data peribadi anda.'
    },
    termsAndConditions: {
      title: 'Terma dan Syarat Servis NJOI',
      description:
        'Terma & Syarat Perkhidmatan NJOI ini adalah untuk Perkhidmatan NJOI dan tujuannya adalah untuk dibaca bersama dengan Terma & Syarat Umum Astro. Sekiranya terdapat konflik, Terma & Syarat Perkhidmatan NJOI ini harus digunapakai.'
    },
    supportMainPage: {
      title: 'Bantuan & Sokongan',
      description:
        'Ketahui tentang isu teknikal, tambah nilai & kredit, saluran, pek & filem, NJOI HD, aplikasi NJOI dan banyak lagi.',
      keywords:
        'Bantuan & Sokongan, Bantuan, Sokongan,  NJOI, NJOI, Tambah Nilai, Baki Kredit, NJOI Masalah Teknikal, NJOI Bantuan Teknikal'
    },
    getNjoiPage: {
      title: 'Dapat NJOI',
      description:
        'Dapatkan NJOI sekarang untuk menikmati hiburan hebat tanpa sebarang komitmen! Daftar sekarang NJOI HD Box, NJOI tidak akan mengecewakan anda. Dapatkannya sekarang!'
    },
    njoiDistributorPage: {
      title: 'Pengedar NJOI',
      description: 'Cari pengedar berhampiran anda untuk dapatkan NJOI HD Box.'
    },
    njoiSignUpPage: {
      description: 'Daftarkan sekarang dan dapat NJOI HD Box!'
    },
    njoiArticleDetailsPage: {
      description:
        'Raya ini lebih jimat, lebih hiburan dengan NJOI Prepaid. Daftarkan sekarang untuk dapat percuma NJOI RM30 dan 2 bulan akses Pek HD!'
    },
    thankYouPage: {
      title: 'Terima Kasih',
      description: 'Terima Kasih untuk pilih NJOI!'
    },
    searchPage: {
      title: 'Cari',
      description:
        'Cari mana-mana pek prabayar, saluran dan filem yang tersedia di NJOI.'
    }
  },
  topMenu: {
    channels: 'Beli Saluran & Pek',
    topUp: 'Tambah Nilai',
    topUpDownloadText:
      'Semuanya senang dengan My NJOI. Tambah nilai dan uruskan semua pembelian anda dengan serta-merta.',
    topUpButtonText: 'Muat Turun Aplikasi',
    topUpViaText: 'Tambah nilai melalui <0>WhatsApp</0> atau <1>cara lain</1>',
    topUpCreditUrl: '/bm-support/bm-top-up-credit',
    topUpOtherMethods: 'Cara lain',
    support: 'Bantuan'
  },
  footer: {
    callUsText:
      "Hubungi Kami atau <0>WhatsApp 'Hi'</0> ke <1>{{ whatsappNumber }}</1>",
    footerMenu: {
      privacyPolicy: 'Polisi Privasi',
      termsOfUse: 'Syarat Pengunaan'
    },
    copyright:
      '© <0>{{ year }}</0> MEASAT Broadcast Network Systems Sdn Bhd 199201008561 (240064-A). Hak Cipta Terpelihara.'
  },
  supportPage: {
    mainHeading: '<0>NJOI Panduan </0><1>& Bantuan</1>',
    mainSubHeading:
      '<0>Ada pertanyaan? </0><1>Cari melalui Pusat Bantuan kami.<1>',
    seachInputPlaceholder: 'Cari soalan atau topik…',
    searchResultTitle: 'Hasil Carian',
    noResults: 'Tiada carian yang sepadan',
    relatedArticlesTitle: 'Artikel Berkaitan',
    contactUsTitle: 'Hubungi Kami',
    whatsappChat: 'WhatsApp Chat',
    contactUsDescription: 'WhatsApp kami untuk mendapatkan bantuan'
  },
  contactUsPage: {
    seoTitle: 'Contact Us',
    mainSubHeading:
      'Tinggalkan maklumat anda dan kami akan menghubungi anda dalam masa 48 jam',
    userNameLabel: 'Nama Penuh Mengikut Mykad / Pasport',
    userEmailLabel: 'Alamat E-mel',
    contactUsFormTC:
      '<0>Dengan mengklik ‘Hantar’ anda bersetuju dengan </0><1>Terma & Syarat NJOI</1><2>, </2><3>Polisi Privasi</3><4> dan </4><5>Notis Privasi</5>',
    fullNamePlaceholder: 'Nama Penuh',
    userEmailErrorText: 'Masukkan e-mel yang sah',
    userPhoneErrorText: 'Masukkan telefon bimbit yang sah',
    userNameErrorText: 'Masukkan nama yang sah'
  },
  thankYouPage: {
    mainHeading:
      'Tahniah! Anda telah mendaftar dan sudah bersedia untuk NJOI hiburan terbaik!',
    mainsubHeading:
      'Wakil NJOI kami akan menghubungi anda mengenai penghantaran box anda dalam masa 48 jam.',
    firstCTA: 'Halaman Utama',
    secondCTA: 'Lebih banyak kandungan'
  },
  webstore: {
    dealers: {
      title: 'Boleh didapati di semua <0>pengedar dan kedai rasmi</0> kami*',
      condition: '*Promo eksklusif hanya untuk pembelian dalam talian',
      findDistributor: 'Cari pengedar berhampiran anda'
    },
    distributor: {
      title: 'Pengedar Rasmi NJOI kami'
    },
    movieDetail: {
      showingOn: 'Tarikh tayangan',
      synopsis: 'Sinopsis',
      cast: 'Pelakon',
      language: 'Bahasa',
      subtitle: 'Sarikata',
      duration: 'Tempoh',
      price: 'Harga'
    }
  },
  njoiForm: {
    summaryTitle: 'Maklumat Pembelian',
    mainHeading: 'Daftar untuk NJOI',
    stepper: {
      addressInfo: 'Info Alamat',
      confirm: 'Sahkan'
    },
    addressInfo: {
      residentialType: 'Jenis kediaman*',
      selectPlaceholder: {
        streetName: 'Pilih atau taip nama jalan',
        houseNumber: 'Pilih atau taip nombor rumah',
        area: 'Pilih atau taip kawasan',
        buildingName: 'Pilih atau taip nama bangunan',
        unitNumber: 'Pilih atau taip nombor unit'
      }
    },
    selectionSection: {
      title: 'Ingin meneruskan pemilihan ini?',
      comboTitle: 'NJOI HD Raya Combo',
      description:
        'Raikan Hari Raya anda dengan hiburan HD terbaik untuk seisi keluarga dan terima duit raya PERCUMA daripada kami!',
      banner:
        '<b>TERMASUK</b> akses 2 bulan kepada Pek HD + RM30 kredit NJOI PERCUMA',
      subtitle: 'Ditayangkan Sekarang',
      learnMore: 'Ketahui Lebih Lanjut'
    },
    installationSection: {
      description:
        'Nikmati pemasangan yang lancar untuk TV dan piring satelit dengan khidmat pemasang yang diluluskan oleh NJOI'
    },
    userInfoSection: {
      title: 'Boleh beritahu serba sedikit tentang diri anda',
      fullnameInputLabel: 'Nama penuh seperti dalam MyKad / Pasport',
      addressInputPlaceholder: 'Alamat Penuh'
    },
    contactSection: {
      title: 'Bagaimana kami boleh menghubungi anda?',
      mobileNumberInputLabel: 'Nombor telefon',
      altNumberInputLabel: 'Nombor telefon alternatif',
      gender: 'Jantina*',
      otpNote:
        'Sila pastikan nombor telefon bimbit anda betul. Kami akan mengesahkan nombor mudah alih anda dengan <0>OTP (One-Time Pin)</0>. Kami akan menggunakan nombor ini untuk menghubungi anda bagi urusan pemasangan dan pembayaran.'
    },
    termsSection: {
      termsInfo:
        'Dengan menekan butang Hantar, anda bersetuju dengan perkara berikut',
      totalFeesText:
        'Bayaran pemasangan sebanyak RM85 perlu dibayar kepada pemasang semasa pemasangan NJOI Box',
      redirectionLink:
        '<0>NJOI </0><1>Terma & Syarat</1><2>, </2><3>Dasar Privasi</3><4> dan </4><5>Notis Privasi</5>'
    },
    errorText: {
      mobilePrefixEmptyError: 'Sila pilihkan telco',
      mobileNumberEmptyError: 'Sila isikan nombor telefon',
      mobileNumberNotValidError: 'Sila isikan nombor telefon yang sah',
      emailNotValidError: 'Sila isikan e-mel yang sah',
      genderEmptyError: 'Sila pilihkan Jantina',
      identityEmptyError: 'Sila pilihkan identiti',
      dobEmptyError: 'Sila masukkan tarikh lahir yang sah'
    },
    confirmPage: {
      title: 'Satu langkah lagi! Sila semak butiran anda',
      included: 'Termasuk <0>Percuma</0>',
      installationFee: 'Kos Pemasangan',
      installationNote: '(Dibayar semasa pemasangan)',
      installationPriceNote:
        '<0>Nota:</0> Harga pemasangan adalah berdasarkan jenis kediaman',
      personalInfo: 'Maklumat peribadi',
      checkboxContent:
        "Dengan menekan butang 'Bayar Sekarang', anda bersetuju dengan perkara berikut",
      termsAndConditionsDetails:
        'Jumlah pembayaran untuk pilihan yang dibuat + Bayaran pemasangan sebanyak RM{{ installationFee }} perlu dibayar kepada pemasang semasa pemasangan NJOI Box',
      payNow: 'Bayar Sekarang'
    },
    submitButtonError:
      'Maaf, kami sedang mengalami gangguan perkhidmatan. Sila cuba sebentar lagi.'
  },
  njoiFormB40: {
    title: 'Daftar untuk Pakej Perpaduan Box NJOI',
    userInfoSection: {
      fullnameInputLabel: 'Nama penuh seperti dalam MyKad',
      identificationNumber: 'Nombor MyKad',
      category: 'Kategori'
    },
    errorText: {
      identificationNumberInvalidError: 'Sila masukkan nombor MyKad yang sah',
      below18YearsOldError:
        'Anda mesti berumur 18 tahun ke atas untuk tawaran ini'
    },
    termsAndConditions: {
      totalPaymentText: 'Jumlah pembayaran untuk pilihan yang dibuat',
      installationFeeText:
        'Bayaran pemasangan perlu dibayar oleh pelanggan kepada pemasang semasa pemasangan NJOI Box',
      redirectionLink:
        '<1>Terma & Syarat</1><0> NJOI</0><2>, </2><3>Dasar Privasi</3><4> dan </4><5>Notis Privasi</5>'
    },
    loading: {
      loading: 'Sila tunggu',
      description: 'Kami memerlukan beberapa minit untuk menyemak butiran anda'
    }
  },
  njoiFormB40ThankYou: {
    success: {
      title: 'Pendaftaran anda berjaya!',
      description:
        'Tahniah! Anda layak untuk Pakej Perpaduan Box NJOI. Wakil NJOI kami akan menghubungi anda dalam masa 48 jam untuk penghantaran box.',
      firstCta: 'HALAMAN',
      secondCta: 'LIHAT HIBURAN LAIN'
    },
    pending: {
      title: 'Pendaftaran hampir selesai',
      description:
        'Kami memerlukan dokumen sokongan anda untuk pengesahan. Wakil NJOI kami akan menghubungi anda dalam masa 48 jam.',
      firstCta: 'HALAMAN',
      secondCta: 'LIHAT HIBURAN LAIN'
    },
    failed: {
      title: 'Maaf, anda tidak layak untuk Pakej Perpaduan',
      description:
        'Jangan risau, kami ada tawaran pek lain yang lebih menarik untuk anda. Selamat ber-NJOI dan terima kasih kerana memilih kami.',
      firstCta: 'HALAMAN',
      secondCta: 'DAFTAR UNTUK PAKEJ LAIN'
    }
  },
  njoiMaxSubmission: {
    description:
      'Harap maaf, anda telah melebihi had percubaan maksimum untuk mendaftar.',
    firstCta: 'HALAMAN',
    secondCta: 'DAFTAR UNTUK PAKEJ LAIN'
  },
  upfrontPayment: {
    mainHeading: 'Select your preferred combo selection to sign up'
  },
  channelPacksPage: {
    tabSection: {
      firstTitle: 'NJOI HD'
    },
    topPurchaseTitle: 'Top {{total}}Pembelian',
    totalChannel_zero: '{{count}} Saluran',
    totalChannel_one: '{{count}} Saluran',
    totalChannel_other: '{{count}} Saluran',
    all: 'Semua',
    movieStatus: {
      earlyBird: 'Promo Belian Awal',
      comingSoon: 'Akan Datang'
    }
  },
  comingSoonPage: {
    comingSoon: 'Akan Datang',
    comingSoonDescription: 'Kami sedang menyediakan, nantikan!'
  },
  search: {
    searchBar: 'Cari Box, Pek…',
    categoryDescription: {
      combo: 'box',
      bundles: 'pek',
      channels: 'saluran',
      movies: 'film'
    },
    loadMore: 'Lihat Lagi',
    category: {
      combo: 'NJOI Box',
      bundles: 'Pek',
      channels: 'Saluran',
      movies: 'Film'
    },
    topBanner: {
      description:
        'Inginkan hiburan lebih? Beli Starter Pek kami untuk menikmati pelbagai rancangan daripada pek prepaid.',
      matchedTitle: 'Kami menemui {{count}} hasil untuk carian anda.',
      unmatchedTitle: 'Tiada hasil ditemui'
    },
    noResult: {
      title: 'Oops, tiada hasil carian sepadan di sini.',
      description:
        'Harap maaf, kami tidak dapat menemui apa-apa persamaan untuk “{{query}}”.'
    },
    invalidSearch: {
      title: 'Uh oh, butiran carian anda tidak sah.',
      description: 'Sila masukkan lebih daripada 2 huruf dan cuba lagi.'
    },
    apiError: {
      title: 'Harap Maaf.',
      description:
        'Kami sedang mengalami gangguan perkhidmatan. Sila cuba sebentar lagi.'
    }
  },
  homePage: {
    popularPacks: {
      title: 'Pek Popular',
      tvChannels_zero: '{{ count }} Saluran',
      tvChannels_one: '{{ count }} Saluran',
      tvChannels_other: '{{ count }} Saluran',
      cta: 'KETAHUI LEBIH LANJUT'
    }
  },
  // #region NJOI + Astro Fibre
  otpVerification: {
    title: { otp: 'Sahkan OTP', mobile: 'Sahkan Nombor Telefon Bimbit' },
    message:
      'SMS pengesahan telah dihantar ke \n {{ mobileNo }}. Sila masukkan kod pengesahan di bawah untuk melengkapkan {{type}} anda.',
    actionType: { otp: 'penyerahan', mobile: 'tempahan' },
    resendGap: 'Hantar semula dalam {{ time }} saat',
    label: 'Kod Pengesahan 6-digit (OTP) ',
    errorMessage: {
      verifyOtp: 'Kod OTP yang anda masukkan adalah salah. Sila cuba lagi.'
    },

    buttons: {
      resend: 'Hantar semula',
      verify: 'Sahkan',
      editMobileNo: 'Ubah Nombor Telefon Bimbit'
    }
  },
  afThankYouPage: {
    header: 'Terima kasih kerana melanggan Astro Fibre',
    notificationMessage:
      'Anda akan dimaklumkan tentang pemasangan Astro Fibre anda dalam masa 48 jam akan datang. Salinan ringkasan pesanan anda juga akan dihantar ke alamat e-mel anda di bawah:',
    enquiryMessage:
      'Jika anda tidak menerima e-mel dalam masa 15 minit seterusnya, sila semak folder e-mel spam anda. Untuk sebarang pertanyaan pesanan, sila hubungi kami melalui:'
  },
  afRegisterInterestPage: {
    title:
      'Maaf, broadband tidak tersedia untuk alamat anda. Adakah anda ingin mendaftar minat anda?',
    descriptionTnC: '*Tertakluk kepada terma dan syarat.'
  },
  afRegisterInterestUpdateContactPage: {
    title:
      'Kemaskini maklumat anda, kami akan menghubungi anda secepat mungkin',
    description:
      'Sila berikan maklumat hubungan anda dan wakil Astro akan menghubungi anda dalam masa 2 hari bekerja untuk membantu anda menaik taraf anda',
    error: {
      validEmail: 'Isikan e-mel yang sah',
      validMobileNumber: 'Isikan nombor telefon yang sah',
      requireMobilePrefix: 'Sila pilih awalan mudah alih'
    }
  },
  afRegisterInterestThankYouPage: {
    title:
      'Kami telah menerima order anda. Kami akan menghubungi anda secepat mungkin.',
    description:
      'Ejen kami akan menghubungi anda dalam masa 2 hari bekerja. Jika anda mempunyai pertanyaan, sila hubungi kami melalui'
  },
  astroCartSearchAddressPage: {
    heading: 'Apakah alamat anda?',
    description:
      'Sila berikan alamat pemasangan anda agar kami dapat memeriksa jika alamat anda berada dalam liputan',
    postCode: 'Poskod*',
    searchAddrCTAPrimary: 'Cari',
    searchAddrCTASecondary: 'Cari lagi',
    notFoundPostCodeError: 'Isikan poskod yang sah',
    form: {
      streetLabel: 'Jalan*',
      houseNumberlabel: 'Nombor Rumah*',
      areaLabel: 'Kawasan*',
      city_stateLabel: 'Bandar / Negeri*',
      buildingLabel: 'Nama bangunan*',
      unitNumberLabel: 'Nombor Unit*',
      submitForm: 'Guna alamat ini'
    },
    selectPlaceholders: {
      streetType: 'Pilih jenis jalan',
      streetName: 'Pilih nama jalan',
      buildingName: 'Pilih nama bangunan',
      unitNumber: 'Pilih nombor unit',
      city: 'Pilih kawasan',
      state: 'Pilih negeri',
      postCode: 'e.g. 57000',
      houseNumber: 'Pilih nombor rumah',
      area: 'Pilih kawasan',
      email: 'john_wick@email.com'
    },
    typePlaceholders: {
      streetType: 'Taip nama jalan',
      streetName: 'Taip jenis jalan',
      unitNumber: 'Taip nombor unit',
      houseNumber: 'Taip nombor rumah',
      area: 'Taip kawasan',
      city: 'Taip bandar',
      state: 'Taip negeri'
    },
    radioButton: {
      landedDesc: 'Teres / Berkembar / Banglo',
      storeyDesc: 'Pangsapuri / Kondo'
    }
  },
  promotionPage: {
    title: 'Soalan lazim',
    imageUrl: '{{baseURL}}/api/v1/static/astro-fibre/may/promotion-image.png',
    firstQA: {
      question:
        'Saya adalah pelanggan NJOI sedia ada/baru, bolehkah saya mendaftar Astro Fibre?',
      answer:
        'Ya, semua pelanggan NJOI sedia ada/baharu boleh melanggan semua tawaran Astro Fibre melalui Aplikasi My NJOI. Kontrak 24 bulan akan bermula selepas mendaftar.'
    },
    secondQA: {
      question: 'Apakah tawaran Astro Fibre untuk pelanggan NJOI?',
      answer:
        'Pelanggan NJOI boleh memilih kelajuan Jalur Lebar daripada 50 Mbps hingga 800 Mbps dan nikmati 1 bulan langganan percuma dan 1 peranti jaringan percuma selama 24 bulan apabila mereka memilih pelan 100 Mbps ke atas. Kontrak baru 24 bulan akan bermula. Promosi ini sah sehingga 30 Jun 2022.'
    },
    thirdQA: {
      question:
        'Saya melanggan Astro Fibre 100/500/800 Mbps dan mendapat 1 bulan langganan percuma. Adakah ia akan ditunjukkan dalam bil saya?',
      answer:
        'Ya, langganan percuma 1 bulan akan ditunjukkan dalam bulan pertama bil Jalur Lebar anda.'
    },
    ctaButtonText: 'Dapatkan Sekarang'
  },
  afErrorHandlingPopup: {
    notInCoverage: {
      title: 'Maaf, Astro Fibre tidak tersedia untuk alamat anda.'
    },
    alreadySubscribed: {
      title: 'Anda sudah melanggan Astro Fibre',
      message:
        'Kami ada tawaran hebat lain sepanjang hari menanti anda. Semak sekarang!'
    },
    unsuccessfulAPIReturn: {
      message:
        'Kami tidak dapat menyelesaikan permintaan anda. Sila semak input anda dan cuba lagi.'
    },
    standardTechnicalReturn: {
      title: 'Isu teknikal'
    },
    unsuccessfulAPIReturnForInvalidInput: {
      message:
        'Anda telah memasukkan maklumat yang tidak sah, sila kemas kini semula.'
    }
  },
  afMeshSelectionPopup: {
    meshWifi: {
      title: 'Faedah menakjubkan WiFi Astro Fibre Mesh',
      benefits: [
        'Liputan tanpa gangguan di setiap sudut rumah anda',
        'Tambah atau alih keluar unit tanpa kontrak langganan',
        'Polisi swap tanpa kerumitan jika mana-mana unit berhenti berfungsi',
        'Jimat kos dengan unit asas terbina dalam dalam penghala WiFi 6 kami',
        'Proses persediaan mudah yang semua orang boleh faham',
        'Penggunaan kuasa yang cekap dengan prestasi maksimum'
      ]
    },
    broadband: {
      title: 'Astro Fibre',
      benefits: [
        'WiFi 6 Diperakui Standard',
        'Rangkaian 802.11k/802.11v',
        'Unit asas EasyMesh terbina dalam, antena 2x 5dBi'
      ]
    }
  },
  afTNC: {
    productTNC: {
      contents: [
        {
          subtitle: '',
          description: [
            'Permohonan Astro Fibre yang diterima melalui My NJOI App sahaja layak mendapat tawaran dan promosi Astro Fibre.',
            'Pelanggan NJOI sedia ada dan pelanggan NJOI baharu yang mendaftar ke <0>Astro Fibre</0> dikehendaki mengekalkan langganan mereka selama <0>sekurang-kurangnya 24 bulan</0> dari tarikh langganan/akaun pengaktifan mereka. Komitmen kontrak <0>TIDAK</0> akan diperbaharui secara automatik pada akhir tempoh hubungan.',
            'Penamatan perkhidmatan awal sebelum tamat kontrak (24 bulan) akan dikenakan bayaran pembatalan sebanyak RM2400 (pro-rata).',
            'Penempatan semula atau pemindahan langganan ke alamat baharu tertakluk kepada kontrak semula 24 bulan baharu dan bayaran penempatan semula akan dikenakan.',
            'Astro berhak untuk menggantung atau menamatkan akses pelanggan kepada Service Astro Fibre pada bila-bila masa tanpa notis kepada pelanggan jika pembayaran tidak diterima.'
          ]
        }
      ]
    }
  },
  afSummaryPage: {
    title: 'Anda hampir selesai! Hanya satu langkah lagi',
    currency: 'RM',
    edit: 'Ubah',
    broadbandItemNameList1: '- Termasuk WiFi 6 router',
    broadbandItemNameList2: '- Kontrak 24 bulan',
    broadbandItemNameList3: '1x WiFi Mesh Extender',
    serviceTax: 'Cukai perkhidmatan',
    serviceTaxPer: '6%',
    installation: 'Pemasangan biasa',
    whatPayTitle: 'Apa yang anda akan bayar',
    firstMonthTotal: 'Jumlah bulan pertama',
    followingMonthsPrice: 'Bulan-bulan berikutnya',
    noteTitle: 'Nota',
    note: 'Kitaran bil bulanan anda akan bermula pada hari pemasangan anda',
    personalInfo: 'Maklumat peribadi',
    termsConditionProductText:
      'Saya telah membaca dan bersetuju dengan <0>Terma dan Syarat Produk</0>',
    termsConditionProductText2:
      'Saya telah membaca dan bersetuju dengan <0>Terma dan Syarat Umum Astro</0> dan sebarang tawaran promosi lain yang dipilih serta terma dan syarat yang berkaitan',
    type: 'Jenis',
    address: 'Alamat',
    personalInfoName: 'Nama',
    idType: 'Jenis ID',
    idNumber: 'Nombor ID',
    myKad: 'MyKad',
    passport: 'Pasport',
    mobile: 'Telefon Bimbit',
    alternate: 'Alternatif',
    termCondition: 'Terma dan Syarat Produk',
    astroTermCondition: 'Terma dan Syarat Umum Astro',
    freeMeshTitle: '(PERCUMA selama 24 bulan)'
  },
  afSignUpPage: {
    userInfoSection: {
      title: 'Kami memerlukan beberapa lagi butiran anda',
      smartcardLabel: 'NJOI Smartcard',
      fullnameInputLabel: 'Nama penuh seperti MyKad / Pasport*',
      identityInputLabel: 'Nombor MyKad / Pasport*',
      uploadImageLabel: 'Muat naik MyKad / Pasport*',
      NRICFrontLabel: 'ID Depan',
      NRICBackLabel: 'ID Belakang',
      utilityBillLabel: 'Bil utiliti',
      passportIDPageLabel: 'Muka Depan Pasport',
      passportVisaLabel: 'Visa Pasport',
      birthDateInputLabel: 'Tarikh lahir*',
      genderInputLabel: 'Jantina*',
      EthnicInputLabel: 'Kumpulan etnik*',
      languageInputLabel: 'Bahasa pilihan',
      infoButtonText: 'Mengapa perlu ini?',
      fileFormat: 'Format PDF, JPG, JPEG, PNG dan HEIC. Saiz maksimum fail 8MB',
      myKadUtilityBillCheckboxText:
        'Alamat pemasangan tidak sama dengan alamat yang tertera pada MyKad saya'
    },
    placeholder: {
      salutation: 'Gelaran',
      birthDate: 'HH / BB / TTTT'
    },
    contactInfoSection: {
      title: 'Bagaimana kita boleh berhubung?',
      description:
        'Kami akan mengesahkan nombor telefon bimbit anda dengan <0>OTP (One-Time PIN)</0> dan akan menggunakan nombor ini untuk menghubungi anda bagi hal berkaitan pemasangan atau pembayaran'
    },
    errorText: {
      NRICNotValidError: 'Sila masukkan NRIC yang sah',
      passportNotValidError: 'Sila masukkan nombor pasport yang sah',
      ageNotValidError: 'Sila masukkan Umur / NRIC yang sah',
      birthDateNotValidError: 'Sila masukkan tarikh lahir yang sah',
      mobileNumberEmptyError: 'Sila masukkan nombor telefon bimbit',
      mobileNumberNotValidError: 'Sila masukkan nombor mudah alih yang sah',
      emailNotValidError: 'Sila masukkan e-mel yang sah',
      imageNotValidError: 'Sila pilih imej dengan format dan saiz yang betul'
    },
    popup: {
      title: 'Kenapa?',
      message:
        'Kami memerlukan salinan dokumen anda untuk mengesahkan identiti dan alamat pemasangan / bil anda. <0/><1/>Dokumen anda akan dikendalikan dengan taraf yang termaktub di bawah APDP (Akta Perlindungan Data Peribadi).'
    }
  },
  afPlanSelectionPage: {
    title: 'Berita baik! Alamat anda dalam liputan',
    recommendedText: 'dicadangkan',
    month: 'bln',
    offerBanner: {
      info: 'WiFi 6 router & 1 Mesh'
    },
    tellMeAboutFibre: {
      ctaText: 'Beritahu saya tentang Astro Fiber'
    },
    knowMore: {
      title: 'Penasihat servis kami',
      description:
        'Team Astro kami boleh menasihati anda tentang penyelesaian terbaik semasa pemasangan dan peningkatan di tempat anda.',
      knowMoreCtaText: 'Beritahu saya tentang Wifi Mesh'
    },
    special: {
      title: 'Lanjutkan liputan anda?',
      info: '100% Jaminan Wifi'
    },
    offerPlan: {
      title: '1 Peranti Mesh',
      discountedPrice: 'PERCUMA selama 24 bln',
      benefits: {
        forAbove100Text:
          'Sesuai untuk <strong>10+ pengguna</strong> pada berbilang peranti',
        for100Text:
          'Sesuai untuk <strong>2 - 3 pengguna</strong> dan <strong>5 peranti</strong>',

        for50Text:
          'Sesuai untuk <strong>1 - 2 pengguna</strong> dan <strong>2 peranti</strong>'
      }
    }
  },
  astroSearchEditSelect: {
    use: 'Guna {{inputText}}'
  }
  // #endregion NJOI + Astro Fibre
};

export default bm;
