import React, { useEffect, useRef, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Col, Row } from '@components/Based/Elements';
import {
  AppDownloadQrCode,
  AppGalleryDownloadIcon,
  AppStoreDownloadIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  PlayStoreDownloadIcon
} from '@components/Based/icons';
import { MenuList, TopUpBox } from '@components/Based/Layout';
import { AnalyticLink, NjoiWebLink } from '@components/Core/Link';
import config from '@src/config';
import {
  closeMenuAnimationEnded,
  openMenuAnimationEnded,
  resetMenuAnimationState
} from '@src/store/menuAnimation/menuAnimationSlice';
import RedirectToAppLink from '@src/web/components/Core/RedirectToAppLink';
import { dfKeyExtract } from '@src/web/helper/digitalFortressKeyExtract';
import {
  sendTopNavigationEvent,
  sendTopUpEvent
} from '@store/analytics/AnalyticSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';

const appStore = [
  {
    url: 'https://play.google.com/store/apps/details?id=my.com.astro.njoihd&hl=en',
    name: 'play-store',
    title: 'Download Via Google Play',
    img: PlayStoreDownloadIcon
  },
  {
    url: 'https://apps.apple.com/my/app/my-njoi/id1556398412',
    name: 'app-store',
    title: 'Download Via App Store',
    img: AppStoreDownloadIcon,
    dataLayer: {}
  },
  {
    url: 'https://appgallery.huawei.com/#/app/C104310663',
    name: 'app-gallery',
    title: 'Download Via App Gallery',
    img: AppGalleryDownloadIcon
  }
];

const DefaultMenu: React.FC<HeaderMenuProps> = ({ openMenu, toggleMenu }) => {
  const menuRef = useRef<HTMLUListElement | null>(null);

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const { language } = useAppSelector((state) => state.language);
  const { isCloseMenuAnimationEnded, isOpenMenuAnimationEnded } =
    useAppSelector((state) => state.menuAnimation);
  const headerTab = useAppSelector((state) =>
    dfKeyExtract(state, 'header_menu')
  );

  const [currentPage, setCurrentPage] = useState<string>('');
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);

  useEffect(() => {
    const handleSubMenu = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as HTMLElement)
      ) {
        toggleSubMenu(null);
      }
    };

    const handleMobileMenuAnimationEnd = () => {
      if (menuRef.current) {
        if (menuRef.current.className.includes('close')) {
          dispatch(closeMenuAnimationEnded());
        } else if (menuRef.current.className.includes('open')) {
          dispatch(openMenuAnimationEnded());
        }
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleSubMenu);
    window.addEventListener('animationend', handleMobileMenuAnimationEnd);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleSubMenu);
      window.removeEventListener('animationend', handleMobileMenuAnimationEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toggleSubMenu(null);
    const pathArray = pathname.split('/').filter((path) => !!path);
    if (
      pathArray[0] === `${language.code}-sign-up` ||
      pathArray[0] === `${language.code}-thank-you` ||
      pathArray[0] === `${language.code}-perpaduan-njoi-box-sign-up` ||
      pathArray[0] ===
        `${language.code}-perpaduan-njoi-box-success-thank-you` ||
      pathArray[0] ===
        `${language.code}-perpaduan-njoi-box-pending-thank-you` ||
      pathArray[0] === `${language.code}-perpaduan-njoi-box-fail-thank-you` ||
      pathArray[0] === `${language.code}-perpaduan-njoi-box-max-submission`
    )
      pathArray[0] = `${language.code}-get-njoi`;
    else if (
      pathArray[0] === `${language.code}-channels` ||
      pathArray[0] === `${language.code}-bundles` ||
      pathArray[0] === `${language.code}-movies` ||
      pathArray[0] === `${language.code}-packs` ||
      pathArray[0] === `${language.code}-campaigns`
    )
      pathArray[0] = `${language.code}-channels-packs`;

    setCurrentPage(pathArray[0] || `${language.code}-home`);

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    // Reset animation state when menu open and close animation complete
    if (
      window.innerWidth < 1024 &&
      !openMenu &&
      isOpenMenuAnimationEnded &&
      isCloseMenuAnimationEnded
    ) {
      dispatch(resetMenuAnimationState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMenu, isOpenMenuAnimationEnded, isCloseMenuAnimationEnded]);

  const toggleSubMenu = (name: string | null) => {
    setOpenSubMenu((prev) => (prev !== name ? name : null));
  };

  const getMenuListClassName = () => {
    if (openMenu && isOpenMenuAnimationEnded) {
      return 'open fit-content';
    } else if (openMenu) {
      return 'open';
    } else if (
      menuRef.current?.className.includes('open') &&
      !isCloseMenuAnimationEnded
    ) {
      return 'close';
    }
    return '';
  };

  return (
    <MenuList
      data-testid="menu-list"
      ref={menuRef}
      className={getMenuListClassName()}
    >
      {headerTab?.map((item: any) => (
        <li id={`item_${item.en.url}`} key={item.en.title}>
          {!item.isDropdown ? (
            item.isWebflow ? (
              <NjoiWebLink
                path={item[language.code].url}
                sendEvent={sendTopNavigationEvent({
                  title: item[language.code].title,
                  url: item[language.code].url
                })}
                title={item[language.code].title}
                className={
                  currentPage === item[language.code].url ? 'active' : ''
                }
              >
                {item[language.code].title}
              </NjoiWebLink>
            ) : (
              <AnalyticLink
                url={`/${item[language.code].url}`}
                title={item[language.code].title}
                external={item.isWebflow}
                className={
                  currentPage === item[language.code].url ? 'active' : ''
                }
                onClick={openMenu ? toggleMenu : undefined}
                disable={
                  pathname.split('/')[1] === `${currentPage}` &&
                  currentPage === item[language.code].url
                }
                sendEvent={sendTopNavigationEvent({
                  title: item[language.code].title,
                  url: item[language.code].url
                })}
              >
                {item[language.code].title}
              </AnalyticLink>
            )
          ) : (
            <>
              <span
                id={`menu_${item[language.code].url}`}
                title={item[language.code].title}
                className={
                  openSubMenu === item[language.code].url ? 'active' : ''
                }
                onClick={() => toggleSubMenu(item[language.code].url)}
              >
                {item[language.code].title}
                {item.isDropdown &&
                  (openSubMenu === item[language.code].url ? (
                    <ArrowUpIcon />
                  ) : (
                    <ArrowDownIcon />
                  ))}
              </span>
              {item.isDropdown && (
                <TopUpBox
                  data-testid="top-up-box"
                  className={
                    openSubMenu === item[language.code].url ? 'open' : ''
                  }
                >
                  <Row flexWrap="nowrap">
                    <Col className="app-download-qr-code">
                      <img
                        id="app-download-qr-code-image"
                        src={AppDownloadQrCode}
                        alt="download"
                        loading="lazy"
                        width="215"
                        height="215"
                      />
                    </Col>
                    <Col>
                      <p id="app-download-desc">
                        {t('topMenu.topUpDownloadText')}
                      </p>
                      <ul className="app-store-list">
                        {appStore.map((item) => (
                          <li key={item.name}>
                            <AnalyticLink
                              url={item.url}
                              external={{
                                openInNewTab: true
                              }}
                              sendEvent={sendTopUpEvent({
                                title: item.title,
                                url: item.url
                              })}
                            >
                              <img
                                id={item.name}
                                src={item.img}
                                alt={item.title}
                                loading="lazy"
                                width="105"
                                height="31"
                              />
                            </AnalyticLink>
                          </li>
                        ))}
                      </ul>
                      <div className="download-app-button">
                        <RedirectToAppLink
                          sendEvent={sendTopUpEvent({
                            title: 'Download App',
                            url: 'https://astroinfo.co/mynjoiappweb'
                          })}
                          ctaButtonText={t('topMenu.topUpButtonText')}
                        />
                      </div>
                      <p>
                        <Trans
                          i18nKey="topMenu.topUpViaText"
                          components={[
                            <AnalyticLink
                              url={`https://api.whatsapp.com/send?phone=${config.whatsappNumber}&text=Hi`}
                              external={{
                                openInNewTab: true
                              }}
                              sendEvent={sendTopUpEvent({
                                title: 'Top Up Via Whatsapp',
                                url: `https://api.whatsapp.com/send?phone=${config.whatsappNumber}&text=Hi`
                              })}
                            >
                              WhatsApp
                            </AnalyticLink>,
                            <AnalyticLink
                              url={t('topMenu.topUpCreditUrl')}
                              external={{
                                openInNewTab: true
                              }}
                              sendEvent={sendTopUpEvent({
                                title: t('topMenu.topUpOtherMethods'),
                                url: t('topMenu.topUpCreditUrl')
                              })}
                            >
                              check out other methods
                            </AnalyticLink>
                          ]}
                        />
                      </p>
                    </Col>
                  </Row>
                </TopUpBox>
              )}
            </>
          )}
        </li>
      ))}
    </MenuList>
  );
};

export default DefaultMenu;
