const en: LanguageResource = {
  common: {
    home: 'Home',
    privacyNotice: 'Privacy Notice',
    getNjoi: 'Get NJOI',
    termsAndConditions: 'Terms & Conditions',
    registerInterest: 'Register Interest',
    submit: 'Submit',
    fullName: 'Full name',
    installation: 'Installation',
    installationAddress: 'Installation Address',
    email: 'Email',
    inputEmptyError: 'This field is required',
    select: 'Select',
    technicalIssueMessage:
      'Sorry, we are currently experiencing technical issues. Please try again later.',
    call: 'Call',
    closePage:
      '<0>Note:</0> Tap on X to close the page and go back to My NJOI App.',
    broadband: 'Broadband',
    expandingCoverageMessage:
      'We are currently working on expanding our broadband coverage.',
    changeAddress: 'Change address',
    mobileNumber: 'Mobile Number',
    altNumber: 'Alternate Number',
    proceed: 'Proceed',
    mobilePrefixEmptyError: 'Please select a mobile prefix',
    landed: 'Landed',
    multiStorey: 'Multi-storey',
    somethingWentWrong: 'Oops, something went wrong',
    okay: 'OK',
    close: 'CLOSE',
    termsAndConditions2: 'Terms and conditions',
    monthlyFee: 'Monthly fee',
    oneTimeFee: 'One-time fee',
    confirmOrder: 'Confirm order',
    firstMonthFree:
      'FREE for first month. Your bill for the first month will be waived.',
    free: 'FREE',
    signUp: 'Sign Up',
    existingCustomer: 'Existing customer?',
    purchaseViaApp: 'Purchase via My NJOI App',
    includedChannels: 'Channels Included',
    njoiChannelAndPackage: 'NJOI Channels & Packages',
    minute_zero: '{{count}} minute',
    minute_one: '{{count}} minute',
    minute_other: '{{count}} minutes',
    mins: '{{count}} mins',
    day_zero: 'for {{count}} day',
    day_one: 'for {{count}} day',
    day_other: 'for {{count}} days',
    dayUnit_zero: '{{count}} day',
    dayUnit_one: '{{count}} day',
    dayUnit_other: '{{count}} days',
    packs: 'Packs',
    starterPack: 'Starter Pack',
    addOnPack: 'Add-on Pack',
    channels: 'Channels',
    movies: 'Movies',
    onNow: 'On Now',
    today: 'Today',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
    noInformationAvailable: 'No information available',
    from: 'From',
    whatsAppButtonText: 'chat with us',
    whatsAppButtonDialogTitle: 'Chat with us',
    whatsAppButtonDialogMessage: 'Hi! Need some help?',
    distributor:
      'Also available in <0>local distributors</0> (online exclusive promo does not apply), <1>Go Shop</1>, <2>Shopee</2> and <3>Lazada</3>.'
  },
  language: {
    eng: 'English',
    may: 'Malay',
    chi: 'Chinese',
    ind: 'Indonesian',
    tam: 'Tamil',
    kor: 'Korean',
    jpn: 'Japanese',
    cmn: 'Mandarin'
  },
  seo: {
    home: {
      title: 'Home',
      description:
        "Get your NJOI HD Box now for more flexi prepaid packs, no contract required and no monthly bill. Just a one-time equipment and installation fee and you're set for life!"
    },
    privacyNotice: {
      description:
        'The Personal Data Protection Act 2010 was introduced by the Government to regulate the processing of personal data in commercial transactions. The Act, which applies to all companies and firms that are established in Malaysia, requires us to inform you of your rights in respect of your personal data that is being processed or that is to be collected and further processed by us and the purposes for the data processing. The Act also requires us to obtain your consent to the processing of your personal data.'
    },
    termsAndConditions: {
      title: 'Terms and Conditions',
      description:
        'These NJOI Services Terms & Conditions are for NJOI Services and are intended to be read together with the Astro General Terms & Conditions. In the event there is a conflict, these NJOI Services Terms & Conditions shall prevail.'
    },
    supportMainPage: {
      title: 'Help & Support',
      description:
        'Find out about technical issues, top up & credit, channels, packs & movie, NJOI HD, My NJOI app and more.',
      keywords:
        'Help & Support , Help, Support, NJOI, NJOI, Top Prepaid, Credit Balance, NJOI Technical Issue, NJOI Troubleshooting'
    },
    getNjoiPage: {
      title: 'Get NJOI',
      description:
        'Get NJOI now to enjoy the great entertainment without any commitment! Register now NJOI HD Box, NJOI will never disappoint you. Get it now!'
    },
    njoiDistributorPage: {
      title: 'NJOI Distributor',
      description: 'Find a distributor near you to get NJOI HD Box.'
    },
    njoiSignUpPage: {
      description: 'Sign Up now to get NJOI HD Box!'
    },
    njoiArticleDetailsPage: {
      description:
        'This Raya festival is better savings and gets more entertainment with NJOI Prepaid. Register now to get free NJOI RM30 and 2 months of HD Pack access!'
    },
    thankYouPage: {
      title: 'Thank You',
      description: 'Thank you for choosing NJOI!'
    },
    searchPage: {
      title: 'Search',
      description:
        'Search any prepaid packs, channels, and movies available in NJOI.'
    }
  },
  topMenu: {
    channels: 'Buy Channels & Packs',
    topUp: 'Top Up',
    topUpDownloadText:
      'Download My NJOI app to top up, purchase and check credit balance anytime anywhere.',
    topUpButtonText: 'Download App',
    topUpViaText:
      'Top up via <0>WhatsApp</0> or <1>check out other methods</1>',
    topUpCreditUrl: '/en-support/en-top-up-credit',
    topUpOtherMethods: 'Check out other methods',
    support: 'Support'
  },
  footer: {
    callUsText:
      "Call us or <0>WhatsApp 'Hi'</0> to <1>{{ whatsappNumber }}</1>",
    footerMenu: {
      privacyPolicy: 'Privacy Policy',
      termsOfUse: 'Terms of Use'
    },
    copyright:
      '© <0>{{ year }}</0> MEASAT Broadcast Network Systems Sdn Bhd 199201008561 (240064-A). All Rights Reserved.'
  },
  supportPage: {
    mainHeading: '<0>NJOI Help </0><1>& Support</1>',
    mainSubHeading:
      '<0>Have questions? </0><1>Search through our Help Centre.<1>',
    seachInputPlaceholder: 'Search for questions or topics…',
    searchResultTitle: 'Search Results',
    noResults: 'No matching results',
    relatedArticlesTitle: 'Related Articles',
    contactUsTitle: 'Contact Us',
    whatsappChat: 'WhatsApp Chat',
    contactUsDescription: 'Chat with us on WhatsApp for assistance'
  },
  contactUsPage: {
    seoTitle: 'Contact Us',
    mainSubHeading:
      'Leave us your information and one of our representatives will contact you within 48 hours',
    userNameLabel: 'Full Name As Per MyKad / Passport',
    userEmailLabel: 'Email Address',
    contactUsFormTC:
      '<0>By clicking “Submit”, you are agreeing to NJOI </0><1>Terms & Conditions</1><2>, </2><3>Privacy Policy</3><4> and </4><5>Privacy Notice</5>',
    fullNamePlaceholder: 'Your Full Name',
    userEmailErrorText: 'Enter valid email address',
    userPhoneErrorText: 'Enter valid mobile number',
    userNameErrorText: 'Enter valid name'
  },
  thankYouPage: {
    mainHeading:
      'Yippee! You’re all signed up to NJOI the best of entertainment',
    mainsubHeading:
      'Our NJOI representative will contact you on your box delivery within 48 hours.',
    firstCTA: 'Home',
    secondCTA: 'More Content'
  },
  webstore: {
    dealers: {
      title: 'Also available at our <0>official distributor or stores</0>.*',
      condition: '*Online exclusive promo does not apply',
      findDistributor: 'Find a distributor near you'
    },
    distributor: {
      title: 'Our Official NJOI Distributors'
    },
    movieDetail: {
      showingOn: 'Showing on',
      synopsis: 'Synopsis',
      cast: 'Cast',
      language: 'Language',
      subtitle: 'Subtitle',
      duration: 'Duration',
      price: 'Price'
    }
  },
  njoiForm: {
    summaryTitle: 'Purchase Summary',
    mainHeading: 'Sign up for NJOI',
    stepper: {
      addressInfo: 'Address Info',
      confirm: 'Confirm'
    },
    addressInfo: {
      residentialType: 'Residential type*',
      selectPlaceholder: {
        streetName: 'Select or type street name',
        houseNumber: 'Select or type house number',
        area: 'Select or type area',
        buildingName: 'Select or type building name',
        unitNumber: 'Select or type unit number'
      }
    },
    selectionSection: {
      title: 'Want to proceed with this selection?',
      comboTitle: 'NJOI HD Raya Combo',
      description:
        'Celebrate Hari Raya with the latest entertainment for the whole family and receive FREE duit raya from us!',
      banner:
        '<b>INCLUDES</b> 2 months access to HD Pack + RM30 FREE NJOI credit',
      subtitle: 'Now Showing',
      learnMore: 'Learn more'
    },
    installationSection: {
      description:
        'Enjoy a seamless installation for TV with a satellite dish by our NJOI-approved installer'
    },
    userInfoSection: {
      title: 'Tell us a bit about yourself',
      fullnameInputLabel: 'Full name as per MyKad / Passport',
      addressInputPlaceholder: 'Full installation address'
    },
    contactSection: {
      title: 'How can we get in touch?',
      mobileNumberInputLabel: 'Mobile Number',
      altNumberInputLabel: 'Alternate Number',
      gender: 'Gender*',
      otpNote:
        'Please make sure your mobile number is correct. We will verfiy your mobile number with <0>OTP (One-Time Pin)</0>. We will use this number to contact you for installation and payment related matters.'
    },
    termsSection: {
      termsInfo: 'By clicking ‘Submit’, you agree to the following',
      totalFeesText:
        'RM85 installation fees to be paid the installer during installation of NJOI Box',
      redirectionLink:
        '<0>NJOI </0><1>Terms & Conditions</1><2>, </2><3>Privacy Policy</3><4> and </4><5>Privacy Notice</5>'
    },
    errorText: {
      mobilePrefixEmptyError: 'Please select a mobile prefix',
      mobileNumberEmptyError: 'Please enter mobile number',
      mobileNumberNotValidError: 'Please enter a valid mobile number',
      emailNotValidError: 'Please enter a valid email',
      genderEmptyError: 'Please select gender',
      identityEmptyError: 'Please select identity',
      dobEmptyError: 'Please select DOB'
    },
    confirmPage: {
      title: 'One step to go! Please check your details',
      included: 'Included <0>Free</0>',
      installationFee: 'Installation Fee',
      installationNote: '(To be paid upon the installation)',
      installationPriceNote:
        '<0>Note:</0> The installation price will be based on the residential type',
      personalInfo: 'Personal Info',
      checkboxContent: 'By clicking ‘Pay Now’, you agree to the following',
      termsAndConditionsDetails:
        'Total payment for the selected option + RM{{ installationFee }} installation fees to be paid to the installer during installation of NJOI Box',
      payNow: 'Pay Now'
    },
    submitButtonError:
      'Sorry, we are currently experiencing technical issues. Please try again later.'
  },
  njoiFormB40: {
    title: 'Sign up for NJOI Pakej Perpaduan Box',
    userInfoSection: {
      fullnameInputLabel: 'Full name as per MyKad',
      identificationNumber: 'MyKad number',
      category: 'Category'
    },
    errorText: {
      identificationNumberInvalidError: 'Please enter a valid MyKad number',
      below18YearsOldError: 'You must be 18 years old and above for this offer'
    },
    termsAndConditions: {
      totalPaymentText: 'Total payment for selected option',
      installationFeeText:
        'Installation fee to be paid by the customer to the installer during NJOI Box installation',
      redirectionLink:
        '<0>NJOI </0><1>Terms & Conditions</1><2>, </2><3>Privacy Policy</3><4> and </4><5>Privacy Notice</5>'
    },
    loading: {
      loading: 'Loading',
      description: 'Just a few minutes, we’re checking your details.'
    }
  },
  njoiFormB40ThankYou: {
    success: {
      title: 'Your sign up is successful!',
      description:
        "Good news! You're eligible for NJOI Pakej Perpaduan Box. Our NJOI representative will contact you for box delivery within 48 hours.",
      firstCta: 'HOME',
      secondCta: 'SEE MORE CONTENT'
    },
    pending: {
      title: "You're almost there",
      description:
        'We require your supporting documents for verification. Our NJOI representative will contact you within 48 hours.',
      firstCta: 'HOME',
      secondCta: 'SEE MORE CONTENT'
    },
    failed: {
      title: "We're sorry, you're not eligible for Pakej Perpaduan",
      description:
        "Don't worry, we have something better for you in other pack offers. Thank you for choosing us, and happy NJOI-ing.",
      firstCta: 'HOME',
      secondCta: 'SIGN UP FOR OTHER PACKAGES'
    }
  },
  njoiMaxSubmission: {
    description:
      'We’re sorry, you’ve exceeded the number of attempts for this submission.',
    firstCta: 'HOME',
    secondCta: 'SIGN UP FOR OTHER PACKAGES'
  },
  upfrontPayment: {
    mainHeading: 'Select your preferred combo selection to sign up'
  },
  channelPacksPage: {
    tabSection: {
      firstTitle: 'NJOI HD'
    },
    topPurchaseTitle: 'Top {{total}}Purchases',
    totalChannel_zero: '{{count}} channel',
    totalChannel_one: '{{count}} channel',
    totalChannel_other: '{{count}} channels',
    all: 'All',
    movieStatus: {
      earlyBird: 'Early Bird Promo',
      comingSoon: 'Coming Soon'
    }
  },
  comingSoonPage: {
    comingSoon: 'Coming Soon',
    comingSoonDescription: 'We are working on it, stay tuned!'
  },
  search: {
    searchBar: 'Search for Box, Pack…',
    categoryDescription: {
      combo: 'box(es)',
      bundles: 'pack(s)',
      channels: 'channel(s)',
      movies: 'movie(s)'
    },
    loadMore: 'Load More',
    category: {
      combo: 'NJOI Box',
      bundles: 'Pack',
      channels: 'Channel',
      movies: 'Movie'
    },
    topBanner: {
      description:
        'Want more entertainment? Purchase our Starter Pack to enjoy content from other prepaid packs.',
      matchedTitle: 'We found {{count}} results for your search.',
      unmatchedTitle: 'No result found'
    },

    noResult: {
      title: 'Oops, no matching results here.',
      description: 'Sorry, we couldn’t find any matches for “{{query}}”.'
    },
    invalidSearch: {
      title: 'Uh oh, your search entry is invalid.',
      description: 'Please enter more than 2 characters and try again.'
    },
    apiError: {
      title: 'Sorry.',
      description:
        'We are currently experiencing technical issues. Please try again later.'
    }
  },
  homePage: {
    popularPacks: {
      title: 'Popular Packs',
      tvChannels_zero: '{{ count }} TV Channel',
      tvChannels_one: '{{ count }} TV Channel',
      tvChannels_other: '{{ count }} TV Channels',
      cta: 'FIND OUT MORE'
    }
  },
  // #region NJOI + Astro Fibre
  otpVerification: {
    title: { otp: 'Verify OTP', mobile: 'Verify Mobile Number' },
    message:
      'A verification SMS was just sent to \n {{ mobileNo }}. Please enter the verification code below to complete your {{type}}.',
    actionType: { otp: 'submission', mobile: 'activation' },
    resendGap: 'Resend in {{ time }} sec',
    label: '6-digit One Time Pin',
    errorMessage: {
      verifyOtp:
        'The OTP code you have inserted is incorrect. Please try again.'
    },
    buttons: {
      resend: 'Resend',
      verify: 'Verify',
      editMobileNo: 'Edit Mobile Number'
    }
  },
  afThankYouPage: {
    header: 'Thank you for subscribing Astro Fibre',
    notificationMessage:
      'You will be notified about your Astro Fibre installation within the next 48 hours. A copy of your order summary will also be sent to your email address below: ',
    enquiryMessage:
      'If you don’t receive an email within the next 15 minutes, please check your junk/spam email folders. For any order enquiry, please reach out to us via:'
  },
  afRegisterInterestPage: {
    title:
      'Sorry, broadband is not available for your address. Would you like to register your interest?',
    descriptionTnC: '*Terms and conditions apply.'
  },
  afRegisterInterestUpdateContactPage: {
    title: 'Update your contact and we’ll get in touch shortly',
    description:
      'Please provide your contact information and an Astro representative will get in touch within 2 working days to assist you with your upgrade',
    error: {
      validEmail: 'Enter a valid email',
      validMobileNumber: 'Enter a valid mobile number',
      requireMobilePrefix: 'Enter a mobile prefix'
    }
  },
  afRegisterInterestThankYouPage: {
    title:
      'We have received your submission. We will get in touch with you soon',
    description:
      'An Astro representative will get in touch with you within 2 working days. If you have questions, please contact our Astro representative via'
  },
  astroCartSearchAddressPage: {
    heading: 'What’s your address?',
    description:
      'Please provide your installation address in order for us to check if your address is supported',
    postCode: 'Postcode*',
    searchAddrCTAPrimary: 'Search',
    searchAddrCTASecondary: 'Search again',
    notFoundPostCodeError: 'Enter valid postcode',
    form: {
      streetLabel: 'Street*',
      houseNumberlabel: 'House number*',
      areaLabel: 'Area*',
      city_stateLabel: 'City / State*',
      buildingLabel: 'Building Name*',
      unitNumberLabel: 'Unit Number*',
      submitForm: 'Use this address'
    },
    selectPlaceholders: {
      streetType: 'Select street type',
      streetName: 'Select street name',
      buildingName: 'Select building name',
      unitNumber: 'Select unit number',
      postCode: 'e.g. 57000',
      city: 'Select city',
      state: 'Select state',
      houseNumber: 'Select House number',
      area: 'Select Area',
      email: 'john_wick@email.com'
    },
    typePlaceholders: {
      streetType: 'Type street name',
      streetName: 'Type street type',
      unitNumber: 'Type unit number',
      houseNumber: 'Type house number',
      area: 'Type area',
      city: 'Type city',
      state: 'Type state'
    },
    radioButton: {
      landedDesc: 'Terrace / Semi D / Bungalow',
      storeyDesc: 'Apartment / Condo'
    }
  },
  promotionPage: {
    title: 'Frequently asked questions',
    imageUrl: '{{baseURL}}/api/v1/static/astro-fibre/eng/promotion-image.png',
    firstQA: {
      question:
        'I am an existing/new NJOI customer, can I sign up for Astro Fibre?',
      answer:
        'Yes, all existing/new NJOI customers can subscribe to all Astro Fibre offerings through the My NJOI App. A 24-month contract will begin upon sign-up.'
    },
    secondQA: {
      question:
        'What are the Astro Fibre offerings available for NJOI customers?',
      answer:
        'NJOI customers may choose from a selection of Broadband speeds from 50 Mbps to 800 Mbps and enjoy 1 month of free subscription and 1 free mesh unit for 24 months when they select a 100 Mbps plan or above. A new 24-month contract will begin upon successful installation. This promotion is valid until 30th June 2022.'
    },
    thirdQA: {
      question:
        'I am subscribed to Astro Fibre 100/500/800 Mbps and entitled to 1 month of free subscription. When will this be reflected in my bill?',
      answer:
        'The 1-month free subscription will be reflected in your first month of the Broadband bill.'
    },
    ctaButtonText: 'Get It Now'
  },
  afErrorHandlingPopup: {
    notInCoverage: {
      title: "We're sorry your area isn't within coverage"
    },
    alreadySubscribed: {
      title: 'You are already subscribed to Astro Fibre',
      message:
        'We have other great deals all day waiting for you. Check them out!'
    },
    unsuccessfulAPIReturn: {
      message:
        'We were unable to complete your request. Please check your input and try again.'
    },
    standardTechnicalReturn: {
      title: 'Technical Issue'
    },
    unsuccessfulAPIReturnForInvalidInput: {
      message: 'You have enter invalid info, please update again.'
    }
  },
  afMeshSelectionPopup: {
    meshWifi: {
      title: 'Amazing benefits of Astro Fibre Mesh WiFi',
      benefits: [
        'Uninterrupted coverage in every corner of your house',
        'Add or remove units without subscription contract',
        'Hassle-free swap policy if any unit stops working',
        'Save cost with built-in base unit in our WiFi 6 router',
        'Easy setup process that everyone can understand',
        'Efficient power consumption with maximum performance'
      ]
    },
    broadband: {
      title: 'Astro Fibre',
      benefits: [
        'WiFi 6 Standard Certified',
        '802.11k/802.11v Network',
        'Built-in EasyMesh base unit, 2x 5dBi antennas'
      ]
    }
  },
  afTNC: {
    productTNC: {
      contents: [
        {
          subtitle: '',
          description: [
            'Astro Fibre applications received through My NJOI App only are entitled to the Astro Fibre offerings and promotions.',
            'Existing NJOI customer and new NJOI customer signing up to <0>Astro Fibre</0> will be required to maintain their subscription to the Astro Fibre Offer for a <0>minimum 24 continuous months</0> from the date of their subscription/account activation. The contract commitment will <0>NOT</0> be auto renewed at the end of contact period.',
            'Early service termination before the expiry of the contract (24 months) will be subjected to an early cancellation fee of RM2400 (pro-rated).',
            'Relocation or transfer of subscription to new address is subjected to a new 24 months recontract and a standard relocation fee applies respectively.',
            'Astro reserves the right to suspend or terminate the Customer’s access to the Astro Fibre Product at any time without notice to the Customer if payments are not received when due.'
          ]
        }
      ]
    }
  },
  afSummaryPage: {
    title: 'Almost there! One step to go',
    currency: 'RM',
    edit: 'Edit',
    broadbandItemNameList1: '- Includes WIFI 6 router',
    broadbandItemNameList2: '- 24 months contract',
    broadbandItemNameList3: '1x Mesh WiFi Extender',
    serviceTax: 'Service tax',
    serviceTaxPer: '6%',
    installation: 'Standard installation',
    whatPayTitle: 'What you’ll pay',
    firstMonthTotal: 'First month total',
    followingMonthsPrice: 'Following months',
    noteTitle: 'Note',
    note: 'Your monthly bill cycle will start on the day of your installation',
    personalInfo: 'Personal info',
    termsConditionProductText:
      'I have read and agreed to the <0>Product Terms and Conditions</0>',
    termsConditionProductText2:
      'I have read and agreed to the <0>Astro General Terms and Conditions</0> and any other promotional offer selected and its associated terms and conditions',
    type: 'Type',
    address: 'Address',
    personalInfoName: 'Name',
    idType: 'ID type',
    idNumber: 'ID number',
    myKad: 'MyKad',
    passport: 'Passport',
    mobile: 'Mobile',
    alternate: 'Alternate',
    termCondition: 'Product Terms and Conditions',
    astroTermCondition: 'Astro General Terms and Conditions',
    freeMeshTitle: '(FREE for 24 months)'
  },
  afSignUpPage: {
    userInfoSection: {
      title: 'We just need a bit more information about you',
      smartcardLabel: 'NJOI Smartcard',
      fullnameInputLabel: 'Full name as per MyKad / Passport*',
      identityInputLabel: 'MyKad / Passport number*',
      uploadImageLabel: 'Upload MyKad / Passport*',
      NRICFrontLabel: 'Front ID',
      NRICBackLabel: 'Back ID',
      utilityBillLabel: 'Utility Bill',
      passportIDPageLabel: 'Passport Front Page',
      passportVisaLabel: 'Passport Visa',
      birthDateInputLabel: 'Date of birth*',
      genderInputLabel: 'Gender*',
      EthnicInputLabel: 'Ethnic group*',
      languageInputLabel: 'Preferred language',
      infoButtonText: 'Why need this?',
      fileFormat:
        'Format PDF, JPG, JPEG, PNG and HEIC.<0 />Maximum file size 5MB',
      myKadUtilityBillCheckboxText:
        'I am not installing Astro at the address stated on my MyKad'
    },
    placeholder: {
      salutation: 'Gelaran',
      birthDate: 'DD / MM / YYYY'
    },
    contactInfoSection: {
      title: 'How can we get in touch?',
      description:
        'We’ll verify your mobile number with <0>OTP (One-Time PIN)</0> and we’ll use this number to contact you for installation and payment related matters'
    },
    errorText: {
      NRICNotValidError: 'Please enter a valid NRIC',
      passportNotValidError: 'Please enter a valid passport number',
      ageNotValidError: 'Age must be above 21',
      birthDateNotValidError: 'Please enter a valid birth date',
      mobileNumberEmptyError: 'Please enter mobile number',
      mobileNumberNotValidError: 'Please enter a valid mobile number',
      emailNotValidError: 'Please enter a valid email',
      imageNotValidError: 'Please select image with correct format and size'
    },
    popup: {
      title: 'Why does Astro need this?',
      message:
        'We require a copy of your documents to verify your identity and to validate your installation / billing address. <0/><1/>Your documents will be handled in compliance with standards set by PDPA (Personal Data Protection Act).'
    }
  },
  afPlanSelectionPage: {
    title: 'Great news! Your address is covered',
    recommendedText: 'recommended',
    month: 'mth',
    offerBanner: {
      info: 'WiFi 6 router & 1 Mesh'
    },
    tellMeAboutFibre: {
      ctaText: 'Tell me about Astro Fibre'
    },
    knowMore: {
      title: 'Complimentary WiFi Advisory Service',
      description:
        'Our Astro team can advise you on the best solution during installation and upgrade on-the-spot.',
      knowMoreCtaText: 'Tell me about Mesh Wifi'
    },
    special: {
      title: 'Extend your coverage?',
      info: '100% Wifi Guarantee'
    },
    offerPlan: {
      title: '1 Mesh device',
      discountedPrice: 'FREE for 24 mths',
      benefits: {
        forAbove100Text:
          'Suitable for <strong>10+ users</strong> on multiple devices',
        for100Text:
          'Suitable for <strong>2 - 3 users</strong> and <strong>5 devices</strong>',
        for50Text:
          'Suitable for <strong>1 - 2 users</strong> and <strong>2 devices</strong>'
      }
    }
  },
  astroSearchEditSelect: {
    use: 'Use {{inputText}}'
  }
  // #endregion NJOI + Astro Fibre
};

export default en;
