import React, { useEffect } from 'react';

import DefaultAnnouncementBar from './DefaultAnnouncementBar';
import DefaultPromotionBar from './DefaultPromotionBar';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import { renderSuccess as announcementRenderSuccess } from '@store/announcement/AnnouncementSlice';
import { renderSuccess as promotionRenderSuccess } from '@store/promotion/PromotionSlice';

const MarketingBar = () => {
  const dispatch = useAppDispatch();
  const barRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const [maxHeight, setMaxHeight] = React.useState<number | 'none'>(0);

  const { isClose: isAnnClosed, isRendered: isAnnRendered } = useAppSelector(
    (state) => state.announcement
  );
  const { isClose: isProClosed, isRendered: isProRendered } = useAppSelector(
    (state) => state.promotion
  );

  useEffect(() => {
    const disableAnimation = () => {
      setMaxHeight('none');
      window.removeEventListener('resize', disableAnimation);
    };
    window.addEventListener('resize', disableAnimation);
    // add EL on 'resize', during resizing set maxHeight to none, immediately remove EL

    return () => {
      window.removeEventListener('resize', disableAnimation);
    };
  }, []);

  useEffect(() => {
    if (isAnnRendered || isProRendered) {
      setMaxHeight(barRef.current.scrollHeight);
      dispatch(announcementRenderSuccess({ renderStatus: false }));
      dispatch(promotionRenderSuccess({ renderStatus: false }));
    }
  }, [isAnnRendered, isProRendered, dispatch]);

  if (isAnnClosed && isProClosed) return null;

  return (
    <div
      data-testid="marketing-bar"
      ref={barRef}
      style={{
        maxHeight,
        overflow: 'hidden',
        transition: 'max-height 1.7s ease-out'
      }}
    >
      <DefaultAnnouncementBar />
      <DefaultPromotionBar />
    </div>
  );
};

export default MarketingBar;
