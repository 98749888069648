import React from 'react';

import { PromotionContainer } from '@components/Based/Bar';
import { ExternalLink } from '@components/Core/Link';
import { ClosableBar } from '@components/Core/TopBar';
import useRefetchApi from '@src/hooks/useRefetchApi';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';
import {
  onClosePromotionBar,
  renderSuccess,
  useFetchPromotionDataQuery
} from '@src/store/promotion/PromotionSlice';
import {
  getOriginalImageUrl,
  getResizeImageUrl
} from '@src/web/helper/imageResizer/ImageResizer';

const DefaultPromotionBar: React.FC = () => {
  const { isClose } = useAppSelector((state) => state.promotion);
  const { isFetching, data, refetch } = useFetchPromotionDataQuery();

  const dispatch = useAppDispatch();
  useRefetchApi(refetch, [isClose], !isClose);

  if (isFetching) {
    return <div className="promotion-wrapper" />;
  }

  if (!data || isClose) {
    return <div></div>;
  }

  return (
    <ClosableBar
      className="promotion-wrapper"
      backgroundColor={data.colourCode}
      onClose={() => dispatch(onClosePromotionBar())}
      isLightCloseButton={!data.isLightBackground}
    >
      <PromotionContainer>
        <div
          className="icon"
          onLoad={() => {
            // Dispatch action to recalculate the height when content change
            dispatch(renderSuccess({ renderStatus: true }));
          }}
        >
          <ExternalLink path={data.link}>
            <picture id="promotion-bar-picture">
              <source
                id="promotion-bar-image-desktop"
                media="(min-width: 962px)"
                srcSet={getResizeImageUrl(data.imageUrl, 1750, 410)}
              />
              <source
                id="promotion-bar-image-tablet"
                media="(min-width: 768px)"
                srcSet={getResizeImageUrl(data.tabletImageUrl, 1750, 410)}
              />
              <img
                id="promotion-bar-image"
                data-testid="promotion-bar-image"
                srcSet={getResizeImageUrl(data.mobileImageUrl, 1750, 410)}
                onError={(e) => {
                  const parentElement = e.currentTarget.parentElement;
                  (parentElement!.children[0] as HTMLImageElement).srcset =
                    getOriginalImageUrl(data.imageUrl);
                  (parentElement!.children[1] as HTMLImageElement).srcset =
                    getOriginalImageUrl(data.tabletImageUrl);
                  (parentElement!.children[2] as HTMLImageElement).srcset =
                    getOriginalImageUrl(data.mobileImageUrl);
                }}
                alt={data.name}
              />
            </picture>
          </ExternalLink>
        </div>
      </PromotionContainer>
    </ClosableBar>
  );
};

export default DefaultPromotionBar;
